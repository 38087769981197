// console.log("testing");

var wordflick = function (ele, opts) {
    let defs = {
        "offset": 0,
        "forward": 1,
        "skip_count": 0,
        "skip_delay": 25,
        "speed": 70,
  "lines": [
    "Make Sure You Update Your Settings", 
    "They're Found At The Top"
  ]
    }
    let config = Object.assign(defs, opts)
console.log(config)
let len = config.lines.length, i = 0, part
    setInterval(function(){
        if(config.forward){
            if(config.offset >= config.lines[i].length){ 
      ++config.skip_count; 
      if(config.skip_count == config.skip_delay){ config.forward = 0; config.skip_count = 0; } 
    }
        } else {
            if(config.offset == 0){ config.forward = 1; i++; config.offset = 0; if(i >= len){ i = 0; } }
        }
        part = config.lines[i].substr(0, config.offset);
        if(config.skip_count == 0){
            if(config.forward){ config.offset++; } else { config.offset--; }
        }

        if(ele != null && ele != undefined){
            ele.text(part);
        }
    },config.speed);
};

let opts = {
"lines" : [
'Hello', 'World', 'Whats good?',
    'I can keep on typing here.',
    'Many thanks to codepen!',
'The testing live makes life so much easier.'
]
}
// wordflick($('#keys'), opts);
//console.log(merged);
